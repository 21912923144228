export default class ApiRequests {}

ApiRequests.goals = {
  create: ({ client }, data) => client.post("/api/goal/", data),
  edit: ({ client }, goal_id, data) => client.post("/api/goal/edit/" + goal_id, data),
  user_list: ({ client }, user_id, data, params) => client.post("/api/goal/user/" + user_id + "/list", data, { params: params }),
  all_goals: ({ client }, data) => client.post("/api/goal/users/list", data),
  goal: ({ client }, goal_id) => client.get("/api/goal/goal/" + goal_id),
  update: ({ client }, data) => client.post("/api/goal/update", data),
  comment: ({ client }, goal_id, data) =>
    client.post("/api/goal/goal/" + goal_id + "/comment", data),
  close: ({ client }, goal_id) => client.put("/api/goal/goal/" + goal_id + "/close"),
  accept: ({ client }, goal_id) => client.put("/api/goal/goal/" + goal_id + "/accept"),
  destroy: ({ client }, goal_id) => client.delete("/api/goal/" + goal_id),
  countLapsedGoals: ({ client }, user_id) => client.get("/api/goal/count_lapsed/" + user_id),
  subordinates_goals: ({ client }, data) => client.post("/api/goal/subordinates_goals", data),
  countOnArgeementGoals: ({ client }, userId) => client.get("/api/goal/countOnArgeementGoals/" + userId),
  agreement_goals: ({ client }, data) => client.post("/api/goal/agreement_goals", data),
  getCompetences: ({ client }, goal_id) => client.get("/api/goal/" + goal_id + "/competences"),
  parentGoalSearch: ({ client }, query) => client.get("/api/goal/parent/search", { params: { query } }),
  parentGoal: ({ client }, goal_id) => client.get("/api/goal/" + goal_id + "/parent-goal"),
  getSubGoals: ({ client }, goal_id ) => client.get("/api/goal/" + goal_id + "/sub-goals"),
};

