export default {
  views: {
    goals_of_user: {
      title: "Мои цели",
    },
    create: {
      title: "Создание цели",
      title_error: "Введите как минимум 3 буквы",
      textarea_error: "Максимальное количество символов 2000",
      goal_description: "Описание цели",
      goal_title: "Название цели",
      description: "Описание",
      competences: "Компетенции",
      deadline: "Период выполнения",
      periods: {
        three_months: "3 месяца",
        six_months: "6 месяцев",
        custom: "Свой крайний срок"
      },
      goal_members: "Участники цели",
      responsible: "Ответственный",
      position: "Должность",
      owner: "Постановщик",
      placeholders: {
        title: "Введите название",
        description: "Введите описание",
        competences: "Введите название компетенции",
        users: "Начните вводить имя ответственного"
      },
      select_user_competences: {
        not_found: "не нашлось таких компетенций(",
        search: "поиск компетенции"
      },
      date_picker: {
        before: "до"
      },
      select_user: {
        not_found: "не нашлось таких пользователей(",
        search: "поиск пользователя"
      },
      buttons: {
        cancel: "Отменить",
        create: "Создать"
      },
      consonant: "Согласующий",
      consonant_error: "Выберите согласующего"
    },
    edit: {
      title: "Редактирование цели",
      edit_button: "Изменить",
      edit_button_view: "Редактировать цель",
      parent_goal: "Родительская цель",
      parent_goal_search: "Выберите родительскую цель",
    },
    sub_goals_table: {
      sub_goals: "Подцели",
      sub_goals_placeholder: "Введите название подцели",
      add_sub_goal: "Добавить подцель",
    },
    modal_sub_goal: {
      confirm_question: "Вы уверены, что хотите удалить цель?",
      confirm: "Подтверждение удаления",
      delete: "Удалить",
      cancel: "Отменить",
    },
    view: {
      access_denied: "Доступ запрещен!",
      deadline: "Крайний срок:",
      without_deadline: "без срока",
      competences: "Компетенции:",
      no_competences: "не привязано ни одной компетенции",
      comments: {
        comments: "Комментарии",
        placeholder: "Напишите свой комментарий",
        error_length: "Введите как минимум 3 буквы",
        send: "Отправить"
      },
      create_date: "Назначена:",
      responsible: "Исполнитель:",
      position: "Должность:",
      no_position: "должность отсутствует",
      owner: "Постановщик:",
      complete_task: "Завершить цель",
      tabs: {
        description: "Описание",
        comments: "Комментарии"
      },
      toasts: {
        comment_sending_success: "Комментарий успешно отправлен",
        goal_close_success: "Цель успешно закрыта",
        comment_sending_error: "Ошибка отправки комменатрий",
        delete_goal_success: "Цель успешно удалена",
        delete_goal_error: "Возникла ошибка при удаления цели",
        goal_accept_success: "Цель согласована и назначена на ответственного",
        goal_accept_error: "Произошла ошибка при согласовании цели"
      },
      goal_statuses: {
        new: "Новая",
        completed: "Завершена",
        lapsed: "Просрочена",
        in_progress: "Выполняется",
        not_accepted: "Не согласована"
      },
      delete_goal: "Удалить цель",
      accept_task: "Согласовать цель"
    },
    my_goals: {
      title: "Мои цели",
      deadline: "Срок цели",
      goals: "Цели",
      status: "Статус",
      no_deadline: "без срока"
    },
    main: {
      title: "Главная",
      create_btn: "Создать цель",
      widgets:{
        my_goals: "Мои цели",
        lapsed: "Отстают по срокам",
        reports: "Мои отчёты",
        agreement: "На согласовании"
      }
    },
    allgoals: {
      modal: {
        placeholders: {
          department: "Все отделы",
          status: "Все статусы"
        },
        responsible_title: "Ответственный",
        owner_title: "Постановщик",
        department_title: "Отдел",
        status_title: "Статус",
        buttons: {
          reset: "Сбросить",
          search: "Поиск"
        }
      },
      headers:{
        created_at: "Дата создания",
        responsible: "Ответственный",
        position: "Должность",
        department: "Отдел",
        owner: "Постановщик",
        goal: "Цель",
        status: "Статус",
        action: "Действие",
        deadline: "Период выполнения",
        accept_or_status: "Согласовать/Статус"
      },
      search_placeholder: "Введите название цели",
    }
  },
  menu: {
      create: "Создать цель",
      my_goals: "Мои цели",
      main: "Главная",
      all_goals: "Все цели",
      head: "Руководитель",
      users_of_department_goals: "Цели подчинённых",
      for_agreement: "Согласование"
  }
};
/* Ланги для целей в централи очень даже ничего, структуру можешь смело брать оттуда */
