export default {
  views: {
    goals_of_user: {
      title: "My goals",
    },
    create: {
      title: "Goal creation",
      title_error: "Enter at least 3 letters",
      textarea_error: "Maximum number of characters is 2000",
      goal_description: "Goal description",
      goal_title: "Goal title",
      description: "Description",
      competences: "Competences",
      deadline: "Deadline",
      periods: {
        three_months: "3 months",
        six_months: "6 months",
        custom: "custom"
      },
      goal_members: "Goal members",
      responsible: "Responsible",
      position: "Position",
      owner: "Task manager",
      placeholders: {
        title: "Enter the title",
        description: "Enter a description",
        competences: "Enter competency name",
        users: "Start typing the name of the responsible"
      },
      select_user_competences: {
        not_found: "no competencies found(",
        search: "competencies search"
      },
      date_picker: {
        before: "until"
      },
      select_user: {
        not_found: "no users found(",
        search: "users search"
      },
      buttons: {
        cancel: "Cancel",
        create: "Create"
      },
      consonant: "Consonant",
      consonant_error: "Choose consonant"
    },
    edit: {
      title: "Edit Goal",
      edit_button: "Change",
      edit_button_view: "Edit Goal",
      parent_goal: "Parent Goal",
      select_parent_goal: "Select Parent Goal"
    },
    sub_goals_table: {
      sub_goals: "Sub Goals",
      sub_goals_placeholder: "Enter sub goal title",
      add_sub_goal: "Add Sub Goal",
    },
    modal_sub_goal: {
      confirm_question: "Are you sure you want to delete the goal?",
      confirm: "Delete Confirmation",
      delete: "Delete",
      cancel: "Cancel",
    },
    view: {
      access_denied: "Access denied!",
      deadline: "Deadline:",
      without_deadline: "without deadline",
      competences: "Competences:",
      no_competences: "there is no competences",
      comments: {
        comments: "Comments",
        placeholder: "Write your comment",
        error_length: "Enter at least 3 letters",
        send: "Send"
      },
      create_date: "Creation date:",
      responsible: "Responsible:",
      position: "Position:",
      no_position: "no position",
      owner: "Task manager:",
      complete_task: "Complete the goal",
      tabs: {
        description: "Description",
        comments: "Comments"
      },
      toasts: {
        comment_sending_success: "Comment has been successfully sent",
        goal_close_success: "Goal has been successfully closed",
        comment_sending_error: "Error while comment sending",
        delete_goal_success: "Goal has been successfully deleted",
        delete_goal_error: "Error while goal deleting",
        goal_accept_success: "The goal is agreed upon and assigned to the person responsible",
        goal_accept_error: "Error occured while agreeing upon the goal"
      },
      goal_statuses: {
        new: "New",
        completed: "Completed",
        lapsed: "Lapsed",
        in_progress: "In progress",
        not_accepted: "Not accepted"
      },
      delete_goal: "Delete the goal",
      accept_task: "Agree on the task"
    },
    my_goals: {
      title: "My goals",
      deadline: "Deadline",
      goals: "Goals",
      status: "Status",
      no_deadline: "without deadline"
    },
    main: {
      title: "Main page",
      create_btn: "Create goal",
      widgets:{
        my_goals: "My goals",
        lapsed: "Behind schedule",
        reports: "My reports",
        agreement: "On agreement"
      }
    },
    allgoals: {
      modal: {
        placeholders: {
          department: "All departments",
          status: "All statuses"
        },
        responsible_title: "Responsible",
        owner_title: "Owner",
        department_title: "Department",
        status_title: "Status",
        buttons: {
          reset: "Reset",
          search: "Search"
        },
        status_options: {
          new: "New",
          completed: "Completed",
          in_progress: "In progress",
          lapsed: "Lapsed"
        }
      },
      headers:{
        created_at: "Created at",
        responsible: "Responsible",
        position: "Position",
        department: "Department",
        owner: "Owner",
        goal: "Goal",
        status: "Status",
        action: "Action",
        deadline: "Deadline",
        accept_or_status: "Accept/Status"
      },
      search_placeholder: "Enter goal name"
    }
  },
  menu: {
    create: "Create a goal",
    my_goals: "My goals",
    main: "Main page",
    all_goals: "All goals",
    head: "Head of department pages",
    users_of_department_goals: "Goals of subordinates",
    for_agreement: "Agreement"
  }
};
